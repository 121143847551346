import React from "react"

import Layout from "../components/common/layout/layout"
import { graphql, Link } from "gatsby"
import { Section, Container } from "../components/global"

const TermsPage = (props) => {
  return (
    <Layout location={props.location}>
      <Section>
        <Container>
          <h1>Terms and conditions</h1>
        </Container>
      </Section>
    </Layout>
  )
}
export default TermsPage
